import React from 'react'
import './Footer.css'

export default () => (
  <div>
    <h2 className="taCenter">
      Follow us{' '}
      <a href="https://instagram.com/codersof.colour/">@codersof.colour</a> on Instagram and {' '}
      <a href="https://twitter.com/codersofcolour/">@codersofcolour</a> on Twitter.
    </h2>
    <br />
    <footer className="footer">
      <div className="container taCenter">
        <span>
          © Copyright {new Date().getFullYear()} All rights reserved. Crafted by{' '}
          <a href="https://codersofcolour.org">Coders of Colour</a>.
        </span>
        <span>
          <a href="https://codersofcolour.org/privacy">Privacy Policy</a>.
        </span>
      </div>
    </footer>
  </div>
)
